import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import "../login/forms.scss";
import * as PR from "../../prime-modules/index";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Login from "../login/Login";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../store/auth";
import Signup from "../login/Signup";
import ForgotPassword from "../ForgotPassword";
import SelectLanguageDropDown from "./LanguageDropDown";
import ResetPassword from "../ResetPassword";
import VerifyEmail from "../VerifyEmail";
import { esimIccidActions } from "../../store/esim";
import { affiliateTrackingInfoActions } from "../../store/affiliateTracking";
import { cartFilterActions } from "../../store/cart";
import { scrollToBundlesActions } from "../../store/scrollToBundles";
import { stateActions } from "../../store/stateKey";
import { envConfig } from "../../constants";
import { googleLogin } from "../../services/api";
import PageLoading from "./PageLoading";
import getAssets from '../../assets/data/brandingAssets.json';
import { getAllCountries } from "../../genericFunctions/GetAllCountries";
import { paymentActions } from "../../store/payment";
import { useToast } from "../../context/ToastContext";

const HeaderMenu = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(useLocation().search);
  const afid = queryParams.get("afid");
  const { showToast, labels, general, isBase64Image, header, redeemVoucher } = useToast();
  const pathname = window.location.pathname?.toLowerCase();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const { search } = useLocation();
  const getCode = new URLSearchParams(search).get("code");
  const state = new URLSearchParams(search).get("state");
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
  const userData = useSelector(state => state.auth.sessionData);
  let username = userData?.firstName + " " + userData?.lastName;
  if (username.length > 12) {
    username = username.slice(0, 10) + '...'
  }
  const cartData = useSelector((state) => state.cart.catalogCart);
  const idPath = useSelector(state => state.auth.idPath)
  const [visible, setVisible] = useState(false);
  const [formState, setFormState] = useState("");
  const accountRef = useRef(null);
  const stateKey = useSelector(state => state.stateKey.stateKey);
  const affiliateTrackingData = useSelector(state => state.affiliateTracking.affiliateTrackingInfo);
  const [loading, setLoading] = useState(false);
  const headers = useMemo(() => {
    return { afid: affiliateTrackingData };
  }, [affiliateTrackingData]);
  const logoutHandler = () => {
    dispatch(authActions.onLogout());
    dispatch(esimIccidActions.deleteEsim());
    dispatch(affiliateTrackingInfoActions.deleteAffiliateTrackingInfo());
    dispatch(cartFilterActions.removeRandomIdInPayment());
    dispatch(paymentActions.removePaymentResponse());
    const getCartObj = { ...cartData };
    delete getCartObj.discountedPercentage;
    delete getCartObj.discountedPrice;
    delete getCartObj.dataAmountForDisplay;
    delete getCartObj.promoCodePrice;
    delete getCartObj.promoCode;
    dispatch(cartFilterActions.setCatalogCart(getCartObj))
  }
  const accountMenu = [
    {
      icon: "pi pi-user",
      label: labels?.LBL0080,
      command: () => navigate("/my-account")
    },
    {
      icon: "pi pi-power-off",
      label: labels?.LBL0081,
      command: logoutHandler
    }
  ]

  const navigatePath = (selectedPath) => {
    pathname !== selectedPath && navigate(selectedPath)
  }

  const showHeader = (id)=>{
   return header?.HEAD0001 ? header?.HEAD0001?.includes(id) : false
  }
  const menuItems = [
    {
      id:'home',
      label: header?.MENU001,
      className: (pathname === '/') ? 'p-menuitem-active' : '',
      command: () => navigatePath('/'),
      visible: showHeader("MENU001"),
    },
    {
      id:'pricing',
      label: header?.MENU002,
      command: () => {
        navigate("/")
        dispatch(scrollToBundlesActions.setScrollToBundles(true));
      },
      visible: showHeader("MENU002"),
    },
    {
      id:'globalEsims',
      label: header?.MENU008,
      className: (pathname === '/global-esims') ? 'p-menuitem-active' : '',
      command: () => {
        navigate("/global-esims")
        dispatch(scrollToBundlesActions.setScrollToBundles(true));
      },
      visible: showHeader("MENU008"),
    },
    {
      id:'partners',
      label: header?.MENU003,
      className: (pathname === '/partners') ? 'p-menuitem-active' : '',
      command: () => navigatePath('/partners'),
      visible: showHeader("MENU003"),
    },
    {
      id:'affiliate',
      label: header?.MENU004,
      className: (pathname === '/affiliate') ? 'p-menuitem-active' : '',
      command: () => navigatePath('/affiliate'),
      visible: showHeader("MENU004"),
    },
    {
      id:'aboutUs',
      label: header?.MENU005,
      className: (pathname === '/about-us') ? 'p-menuitem-active' : '',
      command: () => navigatePath('/about-us'),
      visible: showHeader("MENU005"),
    },
    {
      id:'contact',
      label: header?.MENU006,
      command: () => {
        navigate("/")
        dispatch(scrollToBundlesActions.setScrollToBundles(true));
      },
      visible: showHeader("MENU006"),
    },
    // {
    //   id: 'redeemVoucher',
    //   label: redeemVoucher.VOUC001,
    //   visible: (screenWidth < 960),
    //   className: (pathname === '/redeem-voucher') ? 'p-menuitem-active menu-item-contact' : 'menu-item-contact',
    //   command: () => navigatePath('/redeem-voucher')
    // },
    // {
    //   id:'login',
    //   label: labels?.LBL0234,
    //   visible: (!isLoggedIn && screenWidth < 960),
    //   command: () => { renderFormState(true, 'login') }
    // },
    // {
    //   id:'signup',
    //   label: labels?.LBL0226,
    //   visible: (!isLoggedIn && screenWidth < 960),
    //   command: () => { renderFormState(true, 'signup') }
    // },
    // {
    //   id: 'myAccount',
    //   label: labels?.LBL0080 + " (" + username + ")",
    //   visible: (isLoggedIn && screenWidth < 960),
    //   command: () => navigate("/my-account"),
    //   className: 'username'
    // },
    // {
    //   id: 'logout',
    //   label: labels?.LBL0081,
    //   visible: (isLoggedIn && screenWidth < 960),
    //   command: logoutHandler
    // }
  ];

  useEffect(() => {
    if (afid) {
      navigate("/");
      dispatch(affiliateTrackingInfoActions.setAffiliateTrackingInfo(afid));
    }
  }, [afid, dispatch, navigate]);

  const renderFormState = useCallback((state, formName) => {
    setVisible(state);
    props.setOpenSignUp && props?.setOpenSignUp(state);
    setFormState(formName);
  }, [props])
  useEffect(() => {
    if(!(getCode && state)){
      const countryCode = envConfig.REACT_APP_DEFAULT_PHONE_COUNTRY.toUpperCase();
      getAllCountries(showToast, dispatch, '', countryCode);
    }
   
  }, [dispatch, getCode, showToast, state])

  useEffect(() => {
    if (getCode && state) {
      setLoading(true);
      if (state === stateKey) {
        const loginDetails = (response) => {
          dispatch(stateActions.removeStateKey());
          if (response.result === "SUCCESS") {
            const callingCode = response.data.phone?.callingCode ? response.data.phone?.callingCode : '';
            getAllCountries(showToast, dispatch, callingCode, '');
            dispatch(authActions.onLogin(response.data));
            dispatch(esimIccidActions.deleteEsim());
            renderFormState(false, "")
            navigate('/');
            setLoading(false);
          } else if (response.result === "FAILED") {
            const error = response.error;
            const errorMsg = (error.errorMsg) ? error.errorMsg : error.summary;
            showToast(error.severity, errorMsg)
            setTimeout(() => {
              navigate('/')
            }, [2000])
            setLoading(false);
          } else {
            setLoading(false);
          }
        }
        googleLogin(getCode, headers, dispatch, loginDetails)
      } else if (stateKey) {
        dispatch(stateActions.removeStateKey())
        showToast("error", labels?.LBL0283)
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [getCode, dispatch, state, headers, stateKey, navigate, renderFormState])

useEffect(() => {
  if(location.hash.includes("#login")) {
    navigate(location.pathname + location?.search)
    !isLoggedIn && renderFormState(true, "login");
  }
}, [location?.hash, location.pathname, location?.search, navigate, renderFormState, isLoggedIn])

  const start = (
    <Link to="/"><PR.Image className="logo" src={isBase64Image(general?.GEN0003) ? `data:image/png;base64,${general?.GEN0003}` : getAssets.logo.logo_light} alt="Logo" width="145"/></Link>
  );
  const end = () => {
    return (
      <div className="flex">
        <SelectLanguageDropDown />
        {/* {(screenWidth > 960) && (
          <PR.Button label={redeemVoucher.VOUC001} className="p-button login-btn" onClick={() => { navigatePath('/redeem-voucher') }} />
        )} */}
        {(screenWidth > 960) && (
          isLoggedIn
            ? <div className="flex align-items-center">
              <div>
                <PR.Menu model={accountMenu} ref={accountRef} popup id="account_menu" className="account-menu-dropdown" />
                <PR.Button icon="pi pi-user" onClick={(event) => accountRef.current.toggle(event)} aria-controls="account_menu" aria-haspopup className="user-button p-button-rounded" />
              </div>
              {userData.firstName &&
                <b>{userData.firstName + " " + userData.lastName}</b>
              }
            </div>
            : <>
              {/* <PR.Button label={labels?.LBL0234} onClick={() => { renderFormState(true, 'login') }} className="p-button login-btn" /> */}
              {/* <PR.Button label={labels?.LBL0226} onClick={() => { renderFormState(true, 'signup') }} /> */}
            </>)
        }
      </div>
    )
  }

  useEffect(() => {
    if (idPath.pathName === 'reset-password') {
      renderFormState(true, "resetPwd")
    } else if (idPath.pathName === 'verify-email' || idPath.pathName === 'update-email') {
      renderFormState(true, "verifyEmail")
    }
  }, [idPath.pathName, isLoggedIn, renderFormState])

  // Sticky Header ====

  useEffect(() => {
    window.addEventListener('scroll', isSticky);
    return () => {
      window.removeEventListener('scroll', isSticky);
    };
  });
  const isSticky = () => {
    const header = document.querySelector('.header');
    const scrollTop = window.scrollY;
    scrollTop >= 1 ? header.classList.add('is-sticky') : header.classList.remove('is-sticky');
  };

  useEffect(() => {
    const handleWindowResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  useEffect(() => {
    if(props?.openSignUp && props?.openSignUp === "signup") {
      renderFormState(true, "signup")
    }
  }, [props.openSignUp, renderFormState])
 
  return (
    <>
      <div className="header-navigation">
        <PR.Menubar model={menuItems} start={start} end={end} />
      </div>
      {/* <PR.Dialog visible={visible} draggable={false} blockScroll={true} style={{ width: '75%' }} onHide={() => { renderFormState(false, ""); dispatch(authActions.deleteStoreIdPath()) }} className="forms-dialog" resizable={false}>
        <div className="grid grid-nogutter header-text">
          <div className="col-12 lg:col-4 md:col-12 hide-mobile">
              <div className="login-bg" style={{ background: `url(${getAssets.feature.form_featureImage}) no-repeat` }}>
                <PR.Image className="logo" src={isBase64Image(general?.GEN0003) ? `data:image/png;base64,${general?.GEN0003}` : getAssets.logo.logo_light} alt="Logo" width="145" />
              </div>
          </div>
          <div className="col-12 lg:col-8 md:col-12">
            {formState === 'login'
              ? <Login renderFormState={renderFormState} />
              : formState === 'signup'
                ? <Signup renderFormState={renderFormState} />
                : formState === 'forgotpwd'
                  ? <ForgotPassword renderFormState={renderFormState} />
                  : formState === 'resetPwd'
                    ? <ResetPassword renderFormState={renderFormState} />
                    : formState === 'verifyEmail'
                      ? <VerifyEmail renderFormState={renderFormState} />
                      : <></>
            }
          </div>
        </div>
      </PR.Dialog> */}
      {
        loading && <PageLoading />
      }
    </>
  );
}
export default HeaderMenu
import getAssets from './assets/data/brandingAssets.json';

export const globalConfig = {
    domainName: getAssets.brand.name,
    dataSize: 'GB',
    defaultCurrency: '$',
    libraries: ['places'],
    allowPhoneNumberLength: 16,
    paymentDefaultCurrency: 'usd',
    paymentDefaultCountry: 'US',
    paymentDefaultLabel: 'eSIM Purchase',
    domainUrl: getAssets.domain,
    supportEmail: getAssets.emails.support,
    contactEmail: getAssets.emails.contact,
    salesEmail: getAssets.emails.sales,
    partnersEmail: getAssets.emails.partners,
    phoneNumber: getAssets.phoneNo,
    allowNameLength: 20,
    toastDisplayTime: 10000,
    deviceInfo: "website",
    isEsimCompatible: false,
    catalogDataAmountperGB: 1000,
    notificationCount:20,
    shopURL:"https://shop.data2go.mobi"
}

export const envConfig = process.env

export const responsiveOptions = [
    {
        breakpoint: "1199px",
        numVisible: 3,
        numScroll: 1
    },
    {
        breakpoint: "991px",
        numVisible: 2,
        numScroll: 1
    },
    {
        breakpoint: "767px",
        numVisible: 1,
        numScroll: 1
    }
];
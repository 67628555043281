import React from 'react';
import HeaderMenu from './layout/HeaderMenu';
import getAssets from '../assets/data/brandingAssets.json';
import { Link, useLocation } from "react-router-dom";
import { useToast } from '../context/ToastContext';
import { globalConfig } from '../constants';

const SubHeader = (props) => {
    const { labels } = useToast();
    const location = useLocation();
    const targetPath = "/global-esims";
    return (
        <section className="inner-header-section aboutus-header-section" style={{ background: `url(${getAssets.about_us.sub_banner}) no-repeat` }}>
            <div className="header">
                <header>
                    <HeaderMenu openSignUp={props.openSignUp} setOpenSignUp={props.setOpenSignUp} />
                </header>
                <div className="fixed-height"></div>
            </div>

            <div className="custom-container">
                <div className="grid grid-nogutter header-text align-items-center global-esims-page">
                    {location.pathname === targetPath ? (
                        <>
                            <div className="col-12">
                                <p className='full-width p-text'>{props.subText}</p>
                            </div>
                            <div className="col-12">
                                <Link to={`${globalConfig.shopURL}`} target='_blank' rel="noopener noreferrer" className="buynow-button">{labels?.LBL0340 ? labels.LBL0340 : "Buy eSIM"}</Link>
                            </div>
                        </>
                    ) : (
                        <div className="col-12">
                            <h1>{props.header}</h1>
                            <p className='full-width'>{props.subText}</p>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
}

export default SubHeader;